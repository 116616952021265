import { ConsultationTypeSelection } from ".";

export default function ConsultationTypeSelectionExamples() {
  function onSelectNurseConsult() {
    console.log("Continue button");
  }

  function onSelectDoctorOnDemand() {
    console.log("Starting new session");
  }

  return (
    <>
      <ConsultationTypeSelection
        onNurseConsultClick={onSelectNurseConsult}
        onDoctorOnDemandConsultClick={onSelectDoctorOnDemand}
      />
    </>
  );
}
