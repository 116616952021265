import { useEffect } from "react";
import WelcomeFlow from "../../components/WelcomeFlow";
import { trackPageViewV2 } from "../../services/analytics-adapter";
import { useGlobalStore } from "../../store";
import ThirdPartyLandingScreen from "./components/ThirdPartyWelcomePage";
import { useNavigate } from "react-router";

export default function WelcomePage() {
  const { state } = useGlobalStore();
  const navigate = useNavigate();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Home:Welcome",
      pageSubSection1: "Home",
      pageSubSection2: "Home:Welcome",
      pageCategory: "Home",
    });
  }, []);

  return (
    <>
      {state.isThirdPartyMember === true && (
        <ThirdPartyLandingScreen
          onContinue={() => {
            navigate("/login");
          }}
        />
      )}

      {state.isThirdPartyMember === false && <WelcomeFlow />}
    </>
  );
}
