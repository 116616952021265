import useGetMemberContactDetails from "@/hooks/useGetMemberContactDetails";
import { trackPageViewV2 } from "@/services/analytics-adapter";
import { logout } from "@/services/core-api-adapter";
import { useGlobalStore } from "@/store";
import { formatAddressForTextArea } from "@/utils";
import { Stack, Typography, Button } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import LoadingSpinner from "@/components/LoadingSpinner";
import Panel from "@/components/Panel";

export default function MyContactDetails() {
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const navigate = useNavigate();

  const onLogoutButtonClick = () => {
    logout().then(() => {
      dispatch({
        type: "CLEAR_CURRENT_USER",
      });
      navigate("/");
    });
  };

  useEffect(() => {
    trackPageViewV2({
      pageName: "Health:Contact details",
      pageSubSection1: "Health",
      pageSubSection2: "Health:Contact details",
      pageCategory: "Health",
    });
  }, []);

  const {
    memberContactDetails,
    isMemberContactDetailsLoading,
    memberContactDetailsFetchError,
  } = useGetMemberContactDetails();

  return (
    <Panel>
      {isMemberContactDetailsLoading ? (
        <Stack alignItems="center">
          <LoadingSpinner />
        </Stack>
      ) : (
        <>
          {memberContactDetails && !memberContactDetailsFetchError && (
            <>
              <Typography variant="h3">
                {memberContactDetails.firstName || state.currentUser.firstName}{" "}
                {memberContactDetails.lastName || state.currentUser.lastName}
              </Typography>
              <Button
                onClick={() => onLogoutButtonClick()}
                variant="text"
                color="primary"
                size="large"
                sx={{
                  fontWeight: 700,
                  minWidth: "auto",
                  mt: 1,
                  p: 0,
                }}
              >
                {t("common.logOutButton")}
              </Button>
              <Stack spacing={2} p={1}>
                {memberContactDetails.mobileNumber && (
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{ color: (theme) => theme.palette.neutral[500] }}
                    >
                      {t("common.phoneNumberInputLabel")}
                    </Typography>
                    <Typography>{memberContactDetails.mobileNumber}</Typography>
                  </Stack>
                )}
                {memberContactDetails.emailAddress && (
                  <Stack>
                    <Typography
                      variant="body2"
                      sx={{ color: (theme) => theme.palette.neutral[500] }}
                    >
                      {t("common.emailAddressInputLabel")}
                    </Typography>
                    <Typography>{memberContactDetails.emailAddress}</Typography>
                  </Stack>
                )}
                {memberContactDetails.homeAddress &&
                  Object.values(memberContactDetails.homeAddress).every(
                    (value) => value
                  ) && (
                    <Stack>
                      <Typography
                        variant="body2"
                        sx={{ color: (theme) => theme.palette.neutral[500] }}
                      >
                        {t("common.physicalAddressInputLabel")}
                      </Typography>
                      <Typography sx={{ whiteSpace: "pre-wrap" }}>
                        {formatAddressForTextArea(
                          memberContactDetails.homeAddress
                        )}
                      </Typography>
                    </Stack>
                  )}
              </Stack>
            </>
          )}

          {(memberContactDetailsFetchError ||
            memberContactDetails === null) && (
            <Typography variant="h2" align="center">
              {t("common.somethingWentWrong")}
            </Typography>
          )}
        </>
      )}
    </Panel>
  );
}
