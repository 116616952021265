import { Stack, Typography, Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { convertPxToRem } from "@/utils";
import CircledBackButton from "@/components/CircledBackButton";
import IconLoader from "@/components/IconLoader";
import theme, { panelStyle } from "@/theme";
import { ThirdPartyServices } from "@/services/core-api-adapter";

interface ThirdPartyConsultationTypeSelectionProps {
  onDoctorConsultClick: () => void;
  onNurseConsultClick: () => void;
  onLaboratoryServicesClick: () => void;
  onPharmacyServicesClick: () => void;
  onClinicAppointmentClick: () => void;
}

const consultationPanelInfo: {
  [key: string]: {
    title: any;
    subtitle: any;
    amount: any;
    width: number;
    priceDependent?: boolean;
  };
} = {
  REMOTE_DOCTOR_CONSULTATION: {
    title: "ThirdPartyConsultationTypeSelection.doctorConsult.title",
    subtitle: "ThirdPartyConsultationTypeSelection.doctorConsult.subtitle",
    amount: "ThirdPartyConsultationTypeSelection.doctorConsult.amount",
    width: 120,
  },
  NURSE_CONSULT: {
    title: "ThirdPartyConsultationTypeSelection.nurseConsult.title",
    subtitle: "ThirdPartyConsultationTypeSelection.nurseConsult.subtitle",
    amount: "ThirdPartyConsultationTypeSelection.nurseConsult.amount",
    width: 90,
  },
  LABORATORY_SERVICES: {
    title: "ThirdPartyConsultationTypeSelection.laboratoryServices.title",
    subtitle: "ThirdPartyConsultationTypeSelection.laboratoryServices.subtitle",
    amount: "ThirdPartyConsultationTypeSelection.laboratoryServices.amount",
    priceDependent: true,
    width: 150,
  },
  PHARMACY_SERVICES: {
    title: "ThirdPartyConsultationTypeSelection.pharmacyServices.title",
    subtitle: "ThirdPartyConsultationTypeSelection.pharmacyServices.subtitle",
    amount: "ThirdPartyConsultationTypeSelection.pharmacyServices.amount",
    priceDependent: true,
    width: 150,
  },
  CLINIC_APPOINTMENT: {
    title: "ThirdPartyConsultationTypeSelection.clinicAppointment.title",
    subtitle: "ThirdPartyConsultationTypeSelection.clinicAppointment.subtitle",
    amount: "ThirdPartyConsultationTypeSelection.clinicAppointment.amount",
    width: 120,
  },
};

function ConsultationPanel({
  service,
  onClick,
}: {
  service: string;
  onClick: () => void;
}) {
  const { t } = useTranslation();

  const transformedService = service.toUpperCase();

  const serviceData =
    transformedService in consultationPanelInfo
      ? consultationPanelInfo[transformedService]
      : null;

  return (
    <Stack
      component="button"
      sx={{
        cursor: "pointer",
        border: "none",
      }}
      aria-label={t(serviceData?.title)}
      color="neutral"
      onClick={onClick}
    >
      <Box padding={4} textAlign="left" sx={{ ...panelStyle, width: "100%" }}>
        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography
                variant="body2"
                color="neutral.light"
                fontWeight={600}
              >
                {t(serviceData?.subtitle)}
              </Typography>
              <Typography variant="h3" fontWeight={600}>
                {t(serviceData?.title)}
              </Typography>
            </Stack>
            <Box
              textAlign="center"
              sx={{
                background: (theme) => theme.palette.primary.main,
                width: serviceData?.width,
                borderRadius: 2,
                padding: 1,
              }}
            >
              <Typography color="primary.contrastText" fontWeight={600}>
                {t(serviceData?.amount)}
              </Typography>
            </Box>
            {serviceData?.priceDependent && (
              <Typography color="neutral.500" variant="body2">
                {t("ThirdPartyConsultationTypeSelection.priceDependentSubText")}
              </Typography>
            )}
          </Stack>
          <IconLoader
            sx={{ fontSize: 30 }}
            color="primary"
            icon="ChevronNextIcon"
          />
        </Stack>
      </Box>
    </Stack>
  );
}

export function ThirdPartyConsultationTypeSelection({
  onDoctorConsultClick,
  onNurseConsultClick,
  onLaboratoryServicesClick,
  onPharmacyServicesClick,
  onClinicAppointmentClick,
}: ThirdPartyConsultationTypeSelectionProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onBackButtonClick() {
    navigate(-1);
  }

  return (
    <Stack
      color="neutral.dark"
      sx={{
        minHeight: "100%",
        justifyContent: "space-between",
        flex: 1,
      }}
    >
      <Stack p={2} spacing={2}>
        <Stack>
          <CircledBackButton showLabel={true} onClick={onBackButtonClick} />
        </Stack>
        <Stack>
          <IconLoader
            icon="ChatHeartIcon"
            color="primary"
            sx={{
              fontSize: convertPxToRem(56),
              alignSelf: "center",
            }}
          />
          <Typography textAlign="center" variant="h1" color="inherit">
            {t("ConsultationTypeSelection.title")}
          </Typography>

          <Stack spacing={6} py={4}>
            <Stack spacing={4}>
              <ConsultationPanel
                service={ThirdPartyServices.NURSE_CONSULT}
                onClick={onNurseConsultClick}
              />

              <ConsultationPanel
                service={ThirdPartyServices.REMOTE_DOCTOR_CONSULTATION}
                onClick={onDoctorConsultClick}
              />
            </Stack>

            <Stack display="flex" position="relative">
              <Divider sx={{ borderWidth: ".5px", bgcolor: "primary" }} />
              <Typography
                variant="body2"
                fontWeight={600}
                fontSize={convertPxToRem(10)}
                sx={{
                  top: "50%",
                  left: "50%",
                  textAlign: "center",
                  transform: "translate(-50%, -50%)",
                  position: "absolute",
                  zIndex: 1,
                  p: 0.5,
                  borderRadius: theme.shape.borderRadius,
                  borderColor: (theme) => theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  borderWidth: 1,
                  borderStyle: "solid",
                  background: () => `${theme.palette.accent2[100]}`,
                  width: 35,
                }}
              >
                {t("ActiveConsultations.dividerLabel")}
              </Typography>
            </Stack>

            <Stack spacing={4}>
              <Stack alignItems="center">
                <Typography variant="h2" fontWeight={600}>
                  {t(
                    "ThirdPartyConsultationTypeSelection.selectAnotherService"
                  )}
                </Typography>
              </Stack>

              <ConsultationPanel
                service={ThirdPartyServices.LABORATORY_SERVICES}
                onClick={onLaboratoryServicesClick}
              />

              <ConsultationPanel
                service={ThirdPartyServices.PHARMACY_SERVICES}
                onClick={onPharmacyServicesClick}
              />

              <ConsultationPanel
                service={ThirdPartyServices.CLINIC_APPOINTMENT}
                onClick={onClinicAppointmentClick}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
