import MembershipDetailDependentsList from ".";
import {
  IdentityDocumentType,
  MembershipStatus,
  MembershipType,
} from "../../services/core-api-adapter";

export default function MembershipDetailDependentsListExamples() {
  return (
    <>
      <MembershipDetailDependentsList
        dependents={[
          {
            isPrimaryCard: true,
            membershipDetails: {
              id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
              type: MembershipType.MAIN_MEMBER,
              number: "UNU-725",
              status: MembershipStatus.ACTIVE,
              beneficiaryCode: "00",
            },
            memberDetails: {
              id: "7f37be7c-31dc-448b-a6b6-a9b64f1b01f2",
              memberFullName: "Susan Persona",
              identityDocumentType: IdentityDocumentType.IdNumber,
              identityDocumentValue: "6605194800082",
              dateOfBirth: "1966-05-19",
            },
          },
          {
            isPrimaryCard: true,
            membershipDetails: {
              id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
              type: MembershipType.MAIN_MEMBER,
              number: "UNU-725",
              status: MembershipStatus.ACTIVE,
              beneficiaryCode: "00",
            },
            memberDetails: {
              id: "7f37be7c-31dc-448b-a6b6-a9b64f1b01f2",
              memberFullName: "Susan Persona",
              identityDocumentType: null,
              identityDocumentValue: null,
              dateOfBirth: "1966-05-19",
            },
          },
        ]}
      />
    </>
  );
}
