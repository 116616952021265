import {
  Autocomplete,
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { createElement, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ActiveConsultationsExamples from "../../components/IntercareMedicalAssistance/components/ActiveConsultations/index.examples.tsx";
import AuthIdentificationTypeSelectionExamples from "../../components/AuthIdentificationTypeSelection/index.examples";
import AwaitCameraPermissionsExamples from "../../components/BinahScanFlow/components/AwaitCameraPermissions/index.examples";
import DeviceRequirementsErrorExamples from "../../components/BinahScanFlow/components/DeviceRequirementsError/index.examples.tsx";
import FaceScanCompleteExamples from "../../components/BinahScanFlow/components/FaceScanComplete/index.examples";
import ScanInstructionsExamples from "../../components/BinahScanFlow/components/ScanInstructions/index.examples.tsx";
import UnsupportedDeviceErrorExamples from "../../components/BinahScanFlow/components/UnsupportedBrowserError/index.examples.tsx";
import BinahScanFlowExamples from "../../components/BinahScanFlow/index.examples";
import BottomNavigationExamples from "../../components/BottomNavigation/index.examples";
import ButtonExamples from "../../components/ButtonsExamples/index.examples";
import CVPFlowExamples from "../../components/CVPFlow/index.examples";
import ChatWithMedicalProfessionalExamples from "../../components/ChatWithMedicalProfessional/index.examples";
import CircledBackButton from "../../components/CircledBackButton";
import CollectIdentificationNumberExamples from "../../components/CollectIdentificationNumber/index.examples";
import CollectMarketingCommunicationPreferencesExamples from "../../components/CollectMarketingCommunicationPreferences/index.examples";
import CollectMemberOnboardingDetailsExamples from "../../components/CollectMemberOnboardingDetails/index.examples.tsx";
import CollectUNUIDExamples from "../../components/CollectUNUID/index.examples";
import CollectUserQueryErrorExamples from "../../components/CollectUserQuery/components/CollectUserQueryError/index.examples";
import CollectUserQueryFormExamples from "../../components/CollectUserQuery/components/CollectUserQueryForm/index.examples.tsx";
import CollectUserQuerySuccessExamples from "../../components/CollectUserQuery/components/CollectUserQuerySuccess/index.examples";
import CollectUserQueryFlow from "../../components/CollectUserQuery/index";
import CommunicationPreferencesInputsExamples from "../../components/CommunicationPreferencesInputs/index.examples";
import CrashAppButtonExamples from "../../components/CrashAppButton/index.examples";
import BenefitsTileExamples from "../../components/Dashboard/components/DashboardTiles/BenefitsTile/index.examples.tsx";
import HealthCheckTileExamples from "../../components/Dashboard/components/DashboardTiles/HealthCheckTile/index.examples.tsx";
import HealthProfileTileExamples from "../../components/Dashboard/components/DashboardTiles/HealthProfileTile/index.examples.tsx";
import MyPeopleTileExamples from "../../components/Dashboard/components/DashboardTiles/MyPeopleTile/index.examples.tsx";
import DateOfBirthInputExamples from "../../components/DateOfBirthInput/index.examples";
import DefaultAccordionExamples from "../../components/DefaultAccordion/index.examples";
import ErrorExamples from "../../components/DefaultError/index.examples";
import DefaultSnackbarExamples from "../../components/DefaultSnackbar/index.examples";
import DoctorBookingConfirmationExamples from "../../components/DoctorBookingConfirmation/index.examples";
import ErrorFallbackWithReloadExamples from "../../components/ErrorFallbackWithReload/index.examples";
import FaceScanBreakoutExamples from "../../components/FaceScanBreakout/index.examples.tsx";
import AvailableHealthChecksExamples from "../../components/AvailableHealthChecks/index.examples.tsx";
import FaceWithIconExamples from "../../components/FaceWithIcon/index.examples";
import FindADoctorExamples from "../../components/FindADoctor/index.examples";
import FlowHeaderExamples from "../../components/FlowHeader/index.examples";
import FrequencyInputExamples from "../../components/FrequencyInput/index.examples";
import FullscreenLoadingIndicatorExamples from "../../components/FullscreenLoadingIndicator/index.examples";
import GetHelpCTAButtonExamples from "../../components/GetHelpCTAButton/index.examples";
import GetMedicalAssistanceModalContentExamples from "../../components/GetMedicalAssistanceModalContent/index.examples";
import HealthMeasurementVerificationStatusExamples from "../../components/HealthMeasurementDetailVerificationStatus/index.examples.tsx";
import HealthMeasurementGaugeExamples from "../../components/HealthMeasurementGauge/index.examples";
import HealthNudgeExamples from "../../components/HealthNudge/index.examples";
import HealthProfileQuestionnaireCompleteExamples from "../../components/HealthProfileQuestionnaireComplete/index.examples";
import HealthRecordCardExamples from "../../components/HealthRecordCard/index.examples";
import HealthRecordEmptyStateExamples from "../../components/HealthRecordEmptyState/index.examples";
import HealthScoreIndicatorExamples from "../../components/HealthScoreIndicator/index.examples";
import HealthScoreIndicatorGaugeExamples from "../../components/HealthScoreIndicatorGauge/index.examples.tsx";
import HeightInputExamples from "../../components/HeightInput/index.examples";
import IconLoaderExamples from "../../components/IconLoader/index.examples";
import IdentificationNumberToggleExamples from "../../components/IdentificationNumberToggle/index.examples.tsx";
import InformationBoxExamples from "../../components/InformationBox/index.examples";
import BreakoutExamples from "../../components/IntercareMedicalAssistance/components/Breakout/index.examples";
import IntercareMedicalAssistanceMoreInfoExamples from "../../components/IntercareMedicalAssistance/components/MoreInfo/index.examples.tsx";
import IntercareMedicalAssistancePromptExamples from "../../components/IntercareMedicalAssistance/components/Prompt/index.examples.tsx";
import RefundExamples from "../../components/IntercareMedicalAssistance/components/Refund/index.examples";
import InternationalPhoneNumberInputExamples from "../../components/InternationalPhoneNumberInput/index.examples";
import MarketplaceDashboardPanelExamples from "../../components/MarketplaceDashboardPanel/index.examples";
import MarketplaceSelfServiceConfirmation from "../../components/MarketplaceSelfServiceConfirmation/index.examples";
import MedicalAssistanceExamples from "../../components/MedicalAssistance/index.examples.tsx";
import MedicalAssistanceDependantSelectionExamples from "../../components/MedicalAssistanceDependantSelection/index.examples";
import MedicalAssistanceFindAHospitalExamples from "../../components/MedicalAssistanceFindAHospital/index.examples.tsx";
import AddDependentsThankYouExamples from "../../components/MembershipAddDependents/components/AddDependentsThankYou/index.examples";
import DependentsOverviewExamples from "../../components/MembershipAddDependents/components/DependentsOverview/index.examples";
import MembershipAddDependentsFormExamples from "../../components/MembershipAddDependents/components/MembershipAddDependentsForm/index.examples";
import MembershipCardExamples from "../../components/MembershipCard/index.examples";
import MembershipDependentsSummaryExamples from "../../components/MembershipDependentsSummary/index.examples";
import MembershipStatusIndicatorExamples from "../../components/MembershipStatusIndicator/index.examples";
import NoProductsLinkedToMemberErrorExamples from "../../components/NoProductsLinkedToMemberError/index.examples";
import OTPInputExamples from "../../components/OTPInput/index.examples";
import OptionToggleExamples from "../../components/OptionToggle/index.examples";
import OutsideConsultationOperatingHoursExamples from "../../components/OutsideConsultationOperatingHours/index.examples";
import PaymentErrorExamples from "../../components/PaymentError/index.examples.tsx";
import PaymentFailureExamples from "../../components/PaymentFailure/index.examples";
import PaymentSuccessExamples from "../../components/PaymentSuccess/index.examples";
import ProcessIndicatorIconExamples from "../../components/ProcessIndicatorIcon/index.examples";
import ProvisionedEmployeeWelcomeExamples from "../../components/ProvisionedEmployeeWelcome/index.examples.tsx";
import PublicBinahScanFlowExamples from "../../components/PublicBinahScanFlow/index.examples";
import QuestionTypeBloodTypeExamples from "../../components/QuestionTypeBloodType/index.examples";
import DateOfBirthFormExamples from "../../components/QuestionTypeDateOfBirth/index.examples";
import QuestionTypeHeightExamples from "../../components/QuestionTypeHeight/index.examples";
import QuestionTypeAllergiesListExamples from "../../components/QuestionTypeMedicalConditionList/index.examples";
import QuestionTypeSexAtBirthExamples from "../../components/QuestionTypeSexAtBirth/index.examples";
import QuestionTypeSmokingFrequencyExamples from "../../components/QuestionTypeSmokingFrequency/index.examples";
import QuestionTypeSmokingStatusExamples from "../../components/QuestionTypeSmokingStatus/index.examples";
import QuestionTypeConditionsStatusExamples from "../../components/QuestionTypeStatus/index.examples";
import QuestionTypeWeightExamples from "../../components/QuestionTypeWeight/index.examples";
import RejectTermsOrConsentDialogExamples from "../../components/RejectTermsOrConsentDialog/index.examples.tsx";
import ServiceAvailabilityTableExamples from "../../components/ServiceAvailabilityList/index.examples";
import SteppedProgressIndicatorExamples from "../../components/SteppedProgressIndicator/index.examples";
import SwitchExamples from "../../components/SwitchExamples/index.examples";
import TabsExamples from "../../components/TabsExamples/index.examples";
import ThankYouExamples from "../../components/ThankYou/index.examples";
import UserRatingFeedbackExamples from "../../components/UserRatingFeedback/index.examples";
import UserRatingFeedbackErrorExamples from "../../components/UserRatingFeedbackError/index.examples";
import WatermarkIconExamples from "../../components/WatermarkIcon/index.examples";
import WeightInputExamples from "../../components/WeightInput/index.examples";
import ModalExamples from "../../components/modals/index.examples";
import LifestyleAndAdviceSectionExamples from "../../components/Dashboard/components/LifestyleAndAdviceSection/index.examples.tsx";
import ChatBotExamples from "../../components/ChatBot/index.examples.tsx";
import {
  IdentityDocumentType,
  MedicalServiceProviderType,
  MembershipStatus,
  MembershipType,
} from "../../services/core-api-adapter";
import { maxWidthConfig } from "../../theme";
import ColourExamples from "../../theme/colour.examples";
import IconsExamples from "../../theme/icons.examples";
import ShadowsExamples from "../../theme/shadows.examples";
import TypographyExamples from "../../theme/typography.examples";
import { convertPascalCaseToWords } from "../../utils";
import MembershipManagementChangeRequestSuccessExamples from "../../components/MembershipManagementChangeRequestSuccess/index.examples.tsx";
import DoctorOnDemandPrimarySymptomsPillExamples from "../../components/DoctorOnDemandPrimarySymptomsPill/index.examples.tsx";
import DoctorOnDemandPrimarySymptomsScrollerExamples from "../../components/Dashboard/components/PayGMemberDashboard/components/DoctorOnDemandPrimarySymptomsScroller/index.examples.tsx";
import ConsultationCardExamples from "../../components/MemberConsultationCard/index.examples.tsx";
import AccountCreationSuccessExamples from "../../components/AccountCreationSuccess/index.examples.tsx";
import MembershipDetailDependentsListExamples from "../../components/MembershipDetailDependentsList/index.examples.tsx";
import DoctorOnDemandPromptExamples from "../../components/IntercareMedicalAssistance/components/DoctorOnDemandPrompt/index.examples.tsx";
import ActiveConsultationOutstandingPaymentExamples from "../../components/IntercareMedicalAssistance/components/ActiveConsultationOutstandingPayment/index.examples.tsx";
import PromptExamples from "../../components/BinahScanFlow/components/Prompt/index.examples.tsx";
import CollectThirdPartyUserDetailsExamples from "../../components/CollectThirdPartyUserDetails/index.examples.tsx";
import AuthGetMemberErrorExamples from "../../components/AuthGetMemberError/index.examples.tsx";
import ConsultationTypeSelectionExamples from "../../components/ConsultationTypeSelection/index.examples.tsx";
import ThirdPartyConsultationTypeSelectionExamples from "../../components/ThirdPartyConsultationTypeSelection/index.examples.tsx";
import ThirdPartyMedicalAssistanceServicePromptExamples from "../../components/ThirdPartyMedicalAssistanceServicePrompt/index.examples.tsx";
import ThirdPartyMemberDashboardExamples from "@/components/Dashboard/components/ThirdPartyMemberDashboard/index.examples.tsx";
import PayGMemberDashboardExamples from "@/components/Dashboard/components/PayGMemberDashboard/index.examples.tsx";
import B2BMemberDashboardExamples from "@/components/Dashboard/components/B2BMemberDashboard/index.examples.tsx";
import PayGMedicalAssistanceCTAExamples from "@/components/Dashboard/components/PayGMemberDashboard/components/PayGMedicalAssistanceCTA/index.examples.tsx";

export function buildProductDetails(data?: any) {
  const productDetails = {
    name: "Template",
    friendlyName: "Template (friendlyName)",
    code: "Template",
    seriesName: "Template",
    termsAndConditionsUri:
      "https://api.develop.unuhealth.org/api/Contract/TERMS_AND_CONDITIONS/1/File.html",
    brochureUri:
      "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    imageUri: "https://placehold.co/28x32/green/white?text=S",
    startDate: "2022-06-27T11:19:20+00:00",
    medicalServiceProvider: MedicalServiceProviderType.WHATSAPP,
    provider: {
      id: "f350c1f0-c8f8-46d6-8aae-519b32d8e187",
      name: "Genric",
      description: null,
      imageUri:
        "/components/MembershipCard/logos/b958fa21-4fd4-49d3-9a07-ed33b314538e.png",
    },
    underwriter: {},
    administrator: {
      id: "bbb82cc0-9ea1-40be-8cb2-80e3bbdf7b61",
      code: "NHG",
      name: "National Health Group",
      imageUri:
        "/components/MembershipCard/logos/4529ef13-b948-44e0-8976-9a6ef9a0b34b.png",
      supportedServices: [
        {
          type: "CALL_CENTRE",
          channels: [
            {
              type: "PHONE",
              value: "+27123456789",
              availability: [],
            },
            {
              type: "EMAIL",
              value: "test@test.com",
              availability: [],
            },
            {
              type: "WHATSAPP",
              value: null,
              availability: [],
            },
          ],
        },
        {
          type: "MEDICAL_ASSISTANCE",
          channels: [
            {
              type: "PHONE",
              value: null,
              availability: [],
            },
            {
              type: "EMAIL",
              value: null,
              availability: [],
            },
            {
              type: "WHATSAPP",
              value: "+27727239355",
              availability: [],
            },
          ],
        },
        {
          type: "COUNSELLING_ASSISTANCE",
          channels: [
            {
              type: "PHONE",
              value: "+27860006969",
              availability: [],
            },
            {
              type: "EMAIL",
              value: null,
              availability: [],
            },
            {
              type: "WHATSAPP",
              value: null,
              availability: [],
            },
          ],
        },
      ],
    },
  };
  return {
    ...productDetails,
    ...data,
  };
}

export function buildMembership(data?: any) {
  const membership = {
    productDetails: buildProductDetails(),
    members: [
      {
        isPrimaryCard: true,
        membershipDetails: {
          id: "5a9e9e18-9142-44a9-8d85-0dc21759c5f0",
          type: MembershipType.MAIN_MEMBER,
          number: "Member565",
          status: MembershipStatus.ACTIVE,
          beneficiaryCode: "00",
        },
        memberDetails: {
          id: "7f37be7c-31dc-448b-a6b6-a9b64f1b01f2",
          memberFullName: "Lesedi Persona",
          identityDocumentType: IdentityDocumentType.IdNumber,
          identityDocumentValue: "9905275800085",
          dateOfBirth: "1996-03-12",
        },
      },
    ],
  };
  return {
    ...membership,
    ...data,
  };
}

export default function PlaygroundPage({
  changeMaxWidth,
}: {
  changeMaxWidth: (arg: string) => void;
}) {
  const navigate = useNavigate();

  useEffect(() => {
    changeMaxWidth(maxWidthConfig.playgroundMaxWidth);
    return () => {
      changeMaxWidth(maxWidthConfig.defaultMaxWidth);
    };
  }, []);

  const pages = [
    {
      name: "Typography",
      component: TypographyExamples,
    },
    {
      name: "Colour",
      component: ColourExamples,
    },
    { name: "Icons", component: IconsExamples },
    { name: "IconLoader", component: IconLoaderExamples },
    { name: "Shadows", component: ShadowsExamples },
    { name: "Switch", component: SwitchExamples },
    { name: "FlowHeader", component: FlowHeaderExamples },
    { name: "FrequencyInput", component: FrequencyInputExamples },
    { name: "OTPInput", component: OTPInputExamples },
    { name: "OptionToggle", component: OptionToggleExamples },
    { name: "DefaultAccordion", component: DefaultAccordionExamples },
    {
      name: "FullscreenLoadingIndicator",
      component: FullscreenLoadingIndicatorExamples,
    },
    {
      name: "SteppedProgressIndicator",
      component: SteppedProgressIndicatorExamples,
    },
    {
      name: "DateOfBirthInput",
      component: DateOfBirthInputExamples,
    },
    {
      name: "WatermarkIcon",
      component: WatermarkIconExamples,
    },
    {
      name: "FaceWithIcon",
      component: FaceWithIconExamples,
    },
    {
      name: "ProcessIndicatorIcon",
      component: ProcessIndicatorIconExamples,
    },
    {
      name: "Modal",
      component: ModalExamples,
    },
    {
      name: "InformationBox",
      component: InformationBoxExamples,
    },
    {
      name: "WeightInput",
      component: WeightInputExamples,
    },
    {
      name: "HeightInput",
      component: HeightInputExamples,
    },
    { name: "QuestionTypeDateOfBirth", component: DateOfBirthFormExamples },
    {
      name: "QuestionTypeBloodType",
      component: QuestionTypeBloodTypeExamples,
    },
    {
      name: "QuestionTypeWeight",
      component: QuestionTypeWeightExamples,
    },
    {
      name: "QuestionTypeSmokingFrequency",
      component: QuestionTypeSmokingFrequencyExamples,
    },
    {
      name: "QuestionTypeSexAtBirth",
      component: QuestionTypeSexAtBirthExamples,
    },
    {
      name: "QuestionTypeConditionsStatus",
      component: QuestionTypeConditionsStatusExamples,
    },
    {
      name: "QuestionTypeSmokingStatus",
      component: QuestionTypeSmokingStatusExamples,
    },
    {
      name: "QuestionTypeHeight",
      component: QuestionTypeHeightExamples,
    },
    {
      name: "QuestionTypeAllergiesList",
      component: QuestionTypeAllergiesListExamples,
    },
    {
      name: "MembershipCard",
      component: MembershipCardExamples,
    },
    {
      name: "HealthProfileQuestionnaireComplete",
      component: HealthProfileQuestionnaireCompleteExamples,
      showHeader: false,
    },
    { name: "HealthScoreIndicator", component: HealthScoreIndicatorExamples },
    { name: "BottomNavigation", component: BottomNavigationExamples },
    {
      name: "HealthNudge",
      component: HealthNudgeExamples,
    },
    {
      name: "CollectMarketingCommunicationPreferences",
      component: CollectMarketingCommunicationPreferencesExamples,
    },
    {
      name: "CommunicationPreferencesInputs",
      component: CommunicationPreferencesInputsExamples,
    },
    {
      name: "HealthRecordCard",
      component: HealthRecordCardExamples,
    },
    {
      name: "HealthRecordEmptyState",
      component: HealthRecordEmptyStateExamples,
    },
    {
      name: "DefaultSnackbar",
      component: DefaultSnackbarExamples,
    },
    {
      name: "HealthMeasurementVerificationStatus",
      component: HealthMeasurementVerificationStatusExamples,
    },
    {
      name: "HealthMeasurementGauge",
      component: HealthMeasurementGaugeExamples,
    },
    {
      name: "Buttons",
      component: ButtonExamples,
    },
    {
      name: "MembershipDependentsSummary",
      component: MembershipDependentsSummaryExamples,
    },
    {
      name: "CrashAppButton",
      component: CrashAppButtonExamples,
    },
    {
      name: "AuthIdentificationTypeSelection",
      component: AuthIdentificationTypeSelectionExamples,
    },
    {
      name: "CollectIdentificationNumber",
      component: CollectIdentificationNumberExamples,
    },
    {
      name: "CollectUNUID",
      component: CollectUNUIDExamples,
    },
    {
      name: "MarketplaceDashboardPanel",
      component: MarketplaceDashboardPanelExamples,
    },
    {
      name: "MarketplaceSelfServiceConfirmation",
      component: MarketplaceSelfServiceConfirmation,
      showHeader: false,
    },
    {
      name: "BinahScanFlow",
      component: BinahScanFlowExamples,
    },
    {
      name: "PublicBinahScanFlow",
      component: PublicBinahScanFlowExamples,
    },
    {
      name: "Error",
      component: ErrorExamples,
    },
    {
      name: "ErrorFallbackWithReload",
      component: ErrorFallbackWithReloadExamples,
    },
    {
      name: "FaceScanComplete",
      component: FaceScanCompleteExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "ServiceAvailablityTable",
      component: ServiceAvailabilityTableExamples,
    },
    {
      name: "GetMedicalAssistanceModalContent",
      component: GetMedicalAssistanceModalContentExamples,
    },
    {
      name: "AwaitCameraPermissions",
      component: AwaitCameraPermissionsExamples,
    },
    {
      name: "InternationalPhoneNumberInput",
      component: InternationalPhoneNumberInputExamples,
    },
    {
      name: "MembershipStatusIndicator",
      component: MembershipStatusIndicatorExamples,
    },
    {
      name: "CVPFlow",
      component: CVPFlowExamples,
      showHeader: false,
    },
    {
      name: "Tabs",
      component: TabsExamples,
    },
    {
      name: "NoProductsLinkedToMemberError",
      component: NoProductsLinkedToMemberErrorExamples,
      showHeader: false,
    },
    {
      name: "GetHelpCTAButton",
      component: GetHelpCTAButtonExamples,
      showHeader: true,
    },
    {
      name: "DeviceRequirementsError",
      component: DeviceRequirementsErrorExamples,
    },
    {
      name: "UnsupportedDeviceError",
      component: UnsupportedDeviceErrorExamples,
    },
    {
      name: "MedicalAssistanceFindAHospital",
      component: MedicalAssistanceFindAHospitalExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "ChatWithMedicalProfessional",
      component: ChatWithMedicalProfessionalExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "OutsideConsultationOperatingHours",
      component: OutsideConsultationOperatingHoursExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "ProvisionedEmployeeWelcome",
      component: ProvisionedEmployeeWelcomeExamples,
    },
    {
      name: "DoctorBookingConfirmationExamples",
      component: DoctorBookingConfirmationExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "FindADoctorExamples",
      component: FindADoctorExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "UserRatingFeedbackExamples",
      component: UserRatingFeedbackExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "ThankYouExamples",
      component: ThankYouExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "UserRatingFeedbackErrorExamples",
      component: UserRatingFeedbackErrorExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "PaymentFailureExamples",
      component: PaymentFailureExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "PaymentSuccessExamples",
      component: PaymentSuccessExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "PaymentErrorExamples",
      component: PaymentErrorExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "RefundExamples",
      component: RefundExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "MedicalAssistanceExamples",
      component: MedicalAssistanceExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "MedicalAssistanceDependantSelectionExamples",
      component: MedicalAssistanceDependantSelectionExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "IntercareMedicalAssistancePromptExamples",
      component: IntercareMedicalAssistancePromptExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "ActiveConsultationOutstandingPaymentExamples",
      component: ActiveConsultationOutstandingPaymentExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "ActiveConsultationsExamples",
      component: ActiveConsultationsExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "MedicalAssistanceMoreInfo",
      component: IntercareMedicalAssistanceMoreInfoExamples,
    },
    {
      name: "RejectTermsOrConsentDialogExamples",
      component: RejectTermsOrConsentDialogExamples,
    },
    {
      name: "CollectMemberOnboardingDetailsExamples",
      component: CollectMemberOnboardingDetailsExamples,
    },
    {
      name: "PayGMedicalAssistanceCTAExamples",
      component: PayGMedicalAssistanceCTAExamples,
    },
    {
      name: "BenefitsTileExamples",
      component: BenefitsTileExamples,
    },
    {
      name: "MyPeopleTileExamples",
      component: MyPeopleTileExamples,
    },
    {
      name: "HealthCheckTileExamples",
      component: HealthCheckTileExamples,
    },
    {
      name: "HealthProfileTileExamples",
      component: HealthProfileTileExamples,
    },
    {
      name: "HealthScoreIndicatorGaugeExamples",
      component: HealthScoreIndicatorGaugeExamples,
    },
    {
      name: "FaceScanInstructionsExamples",
      component: AvailableHealthChecksExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "FaceScanBreakoutExamples",
      component: FaceScanBreakoutExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "ScanInstructionsExamples",
      component: ScanInstructionsExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "BreakoutExamples",
      component: BreakoutExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "MembershipAddDependentsFormExamples",
      component: MembershipAddDependentsFormExamples,
      showHeader: true,
      includePadding: true,
    },
    {
      name: "IdentificationNumberToggleExamples",
      component: IdentificationNumberToggleExamples,
      showHeader: true,
      includePadding: true,
    },
    {
      name: "AddDependentsThankYouExamples",
      component: AddDependentsThankYouExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "DependentsOverviewExamples",
      component: DependentsOverviewExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "CollectUserQueryFormExamples",
      component: CollectUserQueryFormExamples,
    },
    {
      name: "CollectUserQueryErrorExamples",
      component: CollectUserQueryErrorExamples,
    },
    {
      showHeader: false,
      includePadding: false,
      name: "CollectUserQuerySuccessExamples",
      component: CollectUserQuerySuccessExamples,
    },
    {
      showHeader: false,
      name: "collectUserQueryFlow",
      component: CollectUserQueryFlow,
    },
    {
      name: "LifestyleAndAdviceSectionExamples",
      component: LifestyleAndAdviceSectionExamples,
    },
    {
      name: "ChatBotExamples",
      component: ChatBotExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "MembershipManagementChangeRequestSuccessExamples",
      component: MembershipManagementChangeRequestSuccessExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "DoctorOnDemandPrimarySymptomsPillExamples",
      component: DoctorOnDemandPrimarySymptomsPillExamples,
    },
    {
      name: "DoctorOnDemandPrimarySymptomsScrollerExamples",
      component: DoctorOnDemandPrimarySymptomsScrollerExamples,
    },
    {
      name: "ConsultationCardExamples",
      component: ConsultationCardExamples,
    },
    {
      name: "AccountCreationSuccessExamples",
      component: AccountCreationSuccessExamples,
      showHeader: false,
    },
    {
      name: "MembershipDetailDependentsListExamples",
      component: MembershipDetailDependentsListExamples,
      showHeader: true,
    },
    {
      name: "DoctorOnDemandPromptExamples",
      component: DoctorOnDemandPromptExamples,
      showHeader: true,
    },
    {
      name: "BinahScanPromptExamples",
      component: PromptExamples,
      showHeader: true,
    },
    {
      name: "CollectThirdPartyUserDetailsExamples",
      component: CollectThirdPartyUserDetailsExamples,
      showHeader: true,
    },
    {
      name: "AuthGetMemberErrorExamples",
      component: AuthGetMemberErrorExamples,
      showHeader: true,
    },
    {
      name: "ConsultationTypeSelectionExamples",
      component: ConsultationTypeSelectionExamples,
      showHeader: true,
    },
    {
      name: "ThirdPartyConsultationTypeSelectionExamples",
      component: ThirdPartyConsultationTypeSelectionExamples,
      showHeader: true,
    },
    {
      name: "ThirdPartyMedicalAssistanceServicePromptExamples",
      component: ThirdPartyMedicalAssistanceServicePromptExamples,
      showHeader: true,
    },
    {
      name: "ThirdPartyMemberDashboardExamples",
      component: ThirdPartyMemberDashboardExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "PayGMemberDashboardExamples",
      component: PayGMemberDashboardExamples,
      showHeader: false,
      includePadding: false,
    },
    {
      name: "B2BMemberDashboardExamples",
      component: B2BMemberDashboardExamples,
      showHeader: false,
      includePadding: false,
    },
  ];

  function PageList() {
    return (
      <>
        <List>
          {pages
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((page) => {
              const pageName = page.name;
              return (
                <ListItemButton key={pageName} href={`/playground/${pageName}`}>
                  <ListItemText>
                    {convertPascalCaseToWords(pageName)}
                  </ListItemText>
                </ListItemButton>
              );
            })}
        </List>
      </>
    );
  }

  function PageHeader({
    pageName,
    shouldShowBackButton = true,
  }: {
    pageName: string;
    shouldShowBackButton?: boolean;
  }) {
    return (
      <Stack
        position="sticky"
        width="100%"
        top="0"
        zIndex="2"
        p={2}
        spacing={1}
        bgcolor="background.paper"
      >
        <Stack p={0} flex={0}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            p={1}
          >
            {shouldShowBackButton && (
              <CircledBackButton onClick={() => navigate("/playground")} />
            )}

            <Box flexGrow={1}>
              <Typography variant="h4" textAlign="center">
                {convertPascalCaseToWords(pageName)}
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Box flexGrow={1}>
            <PlaygroundSearch />
          </Box>
          <Button href="/">Exit Playground</Button>
        </Stack>
      </Stack>
    );
  }

  function PlaygroundSearch() {
    const searchOptions = pages
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((page) => ({
        label: page.name,
      }));

    const onAutocompleteChange = (
      _event: React.ChangeEvent<any>,
      value: {
        label: string;
      } | null
    ) => {
      if (value) {
        navigate(`/playground/${value.label}`);
      }
    };

    function CustomPaperComponent({ props }: any) {
      return (
        <Paper
          sx={{
            border: 1,
            borderColor: "neutral.300",
            "&:hover": {
              borderColor: "primary.main",
            },
          }}
          {...props}
        />
      );
    }

    return (
      <Autocomplete
        options={searchOptions}
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search page"
            placeholder="Type something"
            variant="outlined"
          />
        )}
        PaperComponent={(props) => <CustomPaperComponent props={props} />}
        onChange={onAutocompleteChange}
      />
    );
  }

  return (
    <>
      <Routes>
        <Route
          path=""
          element={
            <Box sx={{ py: 2 }}>
              <PageHeader pageName="Home" shouldShowBackButton={false} />
              <PageList />
            </Box>
          }
        />
        {pages.map((page) => {
          const pageName = page.name;
          return (
            <Route
              key={pageName}
              path={pageName}
              element={
                <Stack spacing={2} p={0} sx={{ minHeight: "100%" }}>
                  {page.showHeader !== false && (
                    <PageHeader pageName={pageName} />
                  )}
                  <Stack px={page.includePadding === false ? 0 : 2}>
                    {createElement(page.component)}
                  </Stack>
                </Stack>
              }
            />
          );
        })}
        <Route
          path="*"
          element={
            <>
              <CircledBackButton onClick={() => navigate("/playground")} />
              <Typography variant="h2">
                Whoops, couldn't find that component's examples
              </Typography>
            </>
          }
        />
      </Routes>
    </>
  );
}
