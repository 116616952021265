import { useEffect } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DefaultDialog, {
  DefaultDialogImplementationProps,
} from "../DefaultDialog";
import MembershipCard from "../MembershipCard";
import { trackEvent } from "../../services/analytics-adapter";
import useGetMemberships from "../../hooks/useGetMemberships";
import LoadingSpinner from "../LoadingSpinner";
import useGetProductType from "../../hooks/useGetProductType";
import { getMembershipCardConfigFromMembership } from "../../services/core-api-adapter";

export default function MembershipCardModal({
  isOpen,
  onClose,
}: DefaultDialogImplementationProps) {
  const { t } = useTranslation();
  const { firstMembership, memberships, membershipsFetchError } =
    useGetMemberships();
  const { isPAYGProduct } = useGetProductType(firstMembership);
  useEffect(() => {
    trackEvent({
      event: "action.membershipCardViewed",
      source: "moreMenu",
    });
  }, []);

  return (
    <DefaultDialog
      isOpen={isOpen}
      onClose={onClose}
      closeButton={false}
      paperStyleProps={{
        p: 2,
        m: 2,
        minWidth: 360,
      }}
    >
      {memberships ? (
        <>
          {membershipsFetchError && (
            <Typography variant="h2" align="center">
              {t("common.somethingWentWrong")}
            </Typography>
          )}

          {!membershipsFetchError && memberships && memberships.length > 0 && (
            <MembershipCard
              config={getMembershipCardConfigFromMembership(firstMembership)}
            />
          )}
        </>
      ) : (
        <Stack alignItems="center">
          <LoadingSpinner />
        </Stack>
      )}

      <Stack spacing={2}>
        {!isPAYGProduct && (
          <Typography
            variant="subtitle2"
            align="center"
            sx={{ color: (theme) => theme.palette.neutral[500] }}
          >
            {t("common.membershipCardDisplaySuggestion")}
          </Typography>
        )}
        <Button size="small" onClick={() => onClose()}>
          {t("common.close")}
        </Button>
      </Stack>
    </DefaultDialog>
  );
}
