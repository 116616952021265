import { Button, Divider, Pagination, Stack, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate } from "react-router";
import useGetMessages from "../../hooks/useGetMessages";
import { markAllMessagesAsRead } from "../../services/core-api-adapter";
import theme from "../../theme";
import { convertPxToRem } from "../../utils";
import CircledBackButton from "../CircledBackButton";
import IconLoader from "../IconLoader";
import LoadingSpinner from "../LoadingSpinner";
import MessageItem from "./components/MessageItem";

export default function Messages() {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const pageSize = 5;

  const [isMarkAllAsReadLoading, setIsMarkAllAsReadLoading] =
    useState<boolean>(false);
  const {
    messages,
    messagesFetchError,
    isMessagesLoading,
    refetchMessages,
    messagesNotFoundError,
    messagesCount,
  } = useGetMessages((page - 1) * pageSize, pageSize);

  const shouldDisplayNoMessagesFound =
    (!isMessagesLoading &&
      !isMarkAllAsReadLoading &&
      messagesFetchError &&
      messagesNotFoundError) ||
    (messages !== undefined && messages.length === 0);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  function onBackButtonClick() {
    navigate("/");
  }

  function markAllMessagesAsReadButtonClicked() {
    setIsMarkAllAsReadLoading(true);

    markAllMessagesAsRead()
      .then(() => {
        refetchMessages();
      })
      .finally(() => {
        setIsMarkAllAsReadLoading(false);
      });
  }

  return (
    <Stack
      sx={{
        minHeight: "100vh",
        overflow: "hidden",
        justifyContent: "space-between",
      }}
    >
      <Stack
        p={2}
        spacing={2}
        sx={{
          backgroundColor: theme.palette.neutral[100],
          borderBottom: `1px solid ${theme.palette.neutral[300]}`,
        }}
      >
        <CircledBackButton showLabel={true} onClick={onBackButtonClick} />

        <Stack spacing={2} alignItems="start">
          <Typography
            variant="h1"
            fontWeight={600}
            fontSize={convertPxToRem(26)}
          >
            {t("Messages.title")}
          </Typography>

          {messages && messages.length > 0 && (
            <Button
              onClick={markAllMessagesAsReadButtonClicked}
              size="small"
              variant="outlined"
              color="primary"
            >
              {t("Messages.markAllAsReadButton")}
            </Button>
          )}
        </Stack>
      </Stack>

      {isMessagesLoading ||
        (isMarkAllAsReadLoading && (
          <Stack position="relative" height="100%" alignSelf="center">
            <Stack
              sx={{
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <LoadingSpinner />
            </Stack>
          </Stack>
        ))}

      {!isMessagesLoading &&
        !isMarkAllAsReadLoading &&
        messagesFetchError &&
        !messagesNotFoundError && (
          <Typography alignSelf="center">
            {t("common.somethingWentWrong")}
          </Typography>
        )}

      {!isMessagesLoading &&
        !isMarkAllAsReadLoading &&
        !messagesFetchError &&
        !messagesNotFoundError &&
        messages &&
        messages.length > 0 && (
          <>
            <Stack
              component="ul"
              divider={<Divider />}
              aria-label={t("Messages.ariaLabel")}
              sx={{ flexGrow: 1 }}
            >
              {messages.map((message) => (
                <MessageItem key={message.id} message={message} />
              ))}
            </Stack>
            {messagesCount && messagesCount > pageSize && (
              <Stack sx={{ p: 2 }}>
                <Pagination
                  aria-label={t("Messages.pagination.ariaLabel")}
                  shape="rounded"
                  color="standard"
                  count={Math.ceil(messagesCount / pageSize)}
                  page={page}
                  onChange={handlePageChange}
                  sx={{ alignSelf: "center" }}
                />
              </Stack>
            )}
          </>
        )}

      {shouldDisplayNoMessagesFound && (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          height="80vh"
          width="100%"
        >
          <IconLoader
            icon="MessageIcon"
            sx={{
              fontSize: convertPxToRem(130),
            }}
          />
          <Typography variant="body1" fontWeight={600} color="neutral.light">
            {t("Messages.noMessages.subtitle")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
