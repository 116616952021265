import { useEffect } from "react";
import AuthFlow from "../../components/AuthFlow";
import { trackPageViewV2 } from "../../services/analytics-adapter";
import { useGlobalStore } from "../../store";
import ThirdPartyAuthFlow from "../../components/ThirdPartyAuthFlow";

export default function AuthPage() {
  const { state } = useGlobalStore();

  useEffect(() => {
    trackPageViewV2({
      pageName: "Auth:Auth flow",
      pageSubSection1: "Auth",
      pageSubSection2: "Auth:Auth flow",
      pageCategory: "Auth",
    });
  }, []);

  return (
    <>
      {state.isThirdPartyMember === true && <ThirdPartyAuthFlow />}

      {state.isThirdPartyMember === false && <AuthFlow />}
    </>
  );
}
