import { Typography } from "@mui/material";
import { ThirdPartyMedicalAssistanceServicePrompt } from ".";

export default function ThirdPartyMedicalAssistanceServicePromptExamples() {
  return (
    <>
      <Typography textAlign="center" variant="h3" color="info.main">
        NURSE_CONSULTATION
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType="NURSE_CONSULTATION"
      />
      <Typography textAlign="center" variant="h3" color="info.main">
        REMOTE_DOCTOR_CONSULTATION
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType="REMOTE_DOCTOR_CONSULTATION"
      />
      <Typography textAlign="center" variant="h3" color="info.main">
        LABORATORY_SERVICES
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType="LABORATORY_SERVICES"
      />
      <Typography textAlign="center" variant="h3" color="info.main">
        PHARMACY_SERVICES
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType="PHARMACY_SERVICES"
      />
      <Typography textAlign="center" variant="h3" color="info.main">
        CLINIC_APPOINTMENT
      </Typography>
      <ThirdPartyMedicalAssistanceServicePrompt
        onBackButtonClick={() => console.log("onBackButtonClick")}
        onContinueButtonClick={() => console.log("onContinueButtonClick")}
        serviceType="CLINIC_APPOINTMENT"
      />
    </>
  );
}
