import { setup, assign, fromPromise } from "xstate";
import {
  GetMemberRequiredActionsItem,
  getMemberRequiredActions,
} from "../../services/core-api-adapter";

export const eventNames = {
  GO_BACK: "GO_BACK",
  COMPLETED_REQUIRED_ACTION: "COMPLETED_REQUIRED_ACTION",
  ERROR: "ERROR",
  SHOW_REQUIRED_ACTION_COLLECTION_ITEM: "SHOW_REQUIRED_ACTION_COLLECTION_ITEM",
  EXIT: "EXIT",
};

interface Context {
  getMemberRequiredActionsResponse: GetMemberRequiredActionsItem[];
}

const initialContextValues: Context = {
  getMemberRequiredActionsResponse: [],
};

const supportedRequiredActionNames = [
  "PROVISIONING_ERROR",
  "PROVISIONED_EMPLOYEE_WELCOME",
  "ACCOUNT_CREATION_SUCCESS",
  "NO_ACTIVE_PLANS",
  "TERMS_AND_CONDITIONS",
  "TERMS_AND_CONDITIONS_PLATFORM",
  "TERMS_AND_CONDITIONS_PLATFORM_COU",
  "TERMS_AND_CONDITIONS_PLATFORM_ZA",
  "TERMS_AND_CONDITIONS_PLATFORM_MZ",
  "TERMS_AND_CONDITIONS_PLATFORM_UG",
  "TERMS_AND_CONDITIONS_PRODUCT",
  "COOKIE_POLICY",
  "PRIVACY_POLICY",
  "CONSENT_FOR_DATA_PROCESSING",
  "COMMUNICATION_CONSENT",
  "BINAH_SCAN",
  "MEMBER_ONBOARDING",
];
export const memberRequiredActionsFlowMachine = setup({
  types: {
    context: {} as Context,
    events: {} as
      | { type: "SHOW_REQUIRED_ACTION_COLLECTION_ITEM" }
      | { type: "EXIT" }
      | { type: "ERROR" }
      | { type: "COMPLETED_REQUIRED_ACTION" },
  },
  actors: {
    fetchMemberRequiredActions: fromPromise(async () => {
      return await getMemberRequiredActions();
    }),
  },
  guards: {
    userHasNextRequiredAction: ({ context }) => {
      if (context.getMemberRequiredActionsResponse.length) {
        const nextRequiredActionName =
          context.getMemberRequiredActionsResponse[0]?.name || "";
        return (
          supportedRequiredActionNames.indexOf(nextRequiredActionName) > -1
        );
      }
      return false;
    },
  },
}).createMachine({
  context: initialContextValues,
  id: "requiredActionsFlow",
  initial: "gettingMemberRequiredActions",
  states: {
    gettingMemberRequiredActions: {
      on: {
        SHOW_REQUIRED_ACTION_COLLECTION_ITEM: {
          target: "collectingRequiredAction",
        },
        EXIT: {
          target: "exit",
        },
        ERROR: {
          target: "gettingMemberRequiredActionsError",
        },
      },
      entry: assign(initialContextValues),
      invoke: {
        id: "fetchMemberRequiredActions",
        src: "fetchMemberRequiredActions",
        input: {},
        onDone: {
          target: "showingNextRequiredActionOrExiting",
          actions: assign({
            getMemberRequiredActionsResponse: ({ event }) => {
              return event.output || [];
            },
          }),
        },
        onError: {
          target: "exit",
        },
      },
    },

    showingNextRequiredActionOrExiting: {
      always: [
        {
          guard: "userHasNextRequiredAction",
          target: "collectingRequiredAction",
        },
        {
          target: "exit",
        },
      ],
    },

    collectingRequiredAction: {
      on: {
        COMPLETED_REQUIRED_ACTION: {
          target: "gettingMemberRequiredActions",
        },
      },
    },

    exit: {
      type: "final",
    },

    gettingMemberRequiredActionsError: {
      on: {
        EXIT: {
          target: "exit",
        },
      },
    },
  },
});
