import useGetMemberships from "@/hooks/useGetMemberships";
import useGetProductType from "@/hooks/useGetProductType";
import { trackEvent, trackPageViewV2 } from "@/services/analytics-adapter";
import { getMembershipCardConfigFromMembership } from "@/services/core-api-adapter";
import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "@/components/LoadingSpinner";
import MembershipCard from "@/components/MembershipCard";

export default function MembershipPanel() {
  const { t } = useTranslation();

  const {
    firstMembership,
    memberships,
    membershipsFetchError,
    isMembershipsLoading,
  } = useGetMemberships();

  const { isPAYGProduct } = useGetProductType(firstMembership);

  useEffect(() => {
    trackEvent({
      event: "action.membershipCardViewed",
      source: "benefits",
    });

    trackPageViewV2({
      pageName: "My benefits:My membership",
      pageSubSection1: "My benefits",
      pageSubSection2: "My benefits:My membership",
      pageCategory: "My benefits",
    });
  }, []);

  return (
    <>
      {isMembershipsLoading ? (
        <Stack alignItems="center">
          <LoadingSpinner />
        </Stack>
      ) : (
        <Stack
          bgcolor="secondary.700"
          sx={{
            borderRadius: 2.5,
          }}
        >
          <Typography p={2} variant="h4">
            {memberships && firstMembership?.productDetails?.name}
          </Typography>
          <Stack
            bgcolor="background.paper"
            sx={{
              border: (theme) => "1px solid " + theme.palette.secondary[700],
              borderRadius: 2.5,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              p: 2,
              pt: 4,
            }}
          >
            {membershipsFetchError && (
              <Typography variant="h2" align="center">
                {t("common.somethingWentWrong")}
              </Typography>
            )}

            {memberships && memberships?.length > 0 && (
              <>
                <MembershipCard
                  config={getMembershipCardConfigFromMembership(
                    firstMembership
                  )}
                />
                {!isPAYGProduct && (
                  <Typography
                    variant="subtitle2"
                    align="center"
                    sx={{ color: (theme) => theme.palette.neutral[500], mt: 4 }}
                  >
                    {t("common.membershipCardDisplaySuggestion")}
                  </Typography>
                )}
              </>
            )}
          </Stack>
        </Stack>
      )}
    </>
  );
}
