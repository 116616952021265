import { ThirdPartyConsultationTypeSelection } from ".";

export default function ThirdPartyConsultationTypeSelectionExamples() {
  function onNurseConsultClick() {
    console.log("Click nurse consult");
  }

  function onDoctorConsultClick() {
    console.log("Click doctor consult");
  }

  function onLaboratoryServicesClick() {
    console.log("Click laboratory services");
  }

  function onPharmacyServicesClick() {
    console.log("Click pharmacy services");
  }

  function onClinicAppointmentClick() {
    console.log("Click clinic appointment services");
  }

  return (
    <>
      <ThirdPartyConsultationTypeSelection
        onNurseConsultClick={onNurseConsultClick}
        onDoctorConsultClick={onDoctorConsultClick}
        onLaboratoryServicesClick={onLaboratoryServicesClick}
        onPharmacyServicesClick={onPharmacyServicesClick}
        onClinicAppointmentClick={onClinicAppointmentClick}
      />
    </>
  );
}
