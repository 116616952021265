import { trackPageViewV2 } from "@/services/analytics-adapter";
import { submitMemberAcknowledgeEvent } from "@/services/core-api-adapter";
import i18n from "@/services/i18n";
import { Stack, Typography, Fade, Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FullscreenBackground from "@/components/FullscreenBackground";
import FullscreenLoadingIndicator from "@/components/FullscreenLoadingIndicator";
import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";


const IMAGES_START_PATH = `/components/AccountCreationSuccessScreen`;
interface AccountCreationSuccessProps {
  onSubmit: () => void;
}

export default function AccountCreationSuccess({
  onSubmit,
}: AccountCreationSuccessProps) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  function onContinueButtonClick() {
    setIsLoading(true);

    submitMemberAcknowledgeEvent("ACCOUNT_CREATION_SUCCESS").finally(() => {
      setIsLoading(false);
      onSubmit();
    });
  }

  useEffect(() => {
    trackPageViewV2({
      pageName: "Sign up:Account creation success",
      pageSubSection1: "Sign up",
      pageSubSection2: "Sign up:Account creation success",
      pageCategory: "Sign up",
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <FullscreenLoadingIndicator />
      ) : (
        <Stack flexGrow={1} p={2} height="100vh">
          <Stack justifyContent="space-between" minHeight="100%">
            <Stack alignSelf="center">
              <Typography fontWeight={400} variant="h2">
                {t("AccountCreationSuccess.title")}
              </Typography>
              <Fade in={true}>
                <Box
                  component="img"
                  my={2}
                  alignSelf="center"
                  sx={{
                    width: "166px",
                    zIndex: 2,
                  }}
                  alt={t("AccountCreationSuccess.welcomeImageTextAlt")}
                  src={`${IMAGES_START_PATH}/${i18n.resolvedLanguage}/welcome-text.svg`}
                />
              </Fade>
            </Stack>

            <Stack direction="row" justifyContent="center" alignItems="center">
              <Fade in={true}>
                <Box
                  component="img"
                  position="absolute"
                  bottom={0}
                  sx={{
                    width: "100%",
                  }}
                  alt={t("AccountCreationSuccess.mainImagetAlt")}
                  src={`${IMAGES_START_PATH}/${i18n.resolvedLanguage}/woman-image.png`}
                />
              </Fade>
            </Stack>

            <Stack spacing={2} sx={{ pb: 4, paddingTop: 3 }}>
              <ButtonWithAnalytics
                page="AccountCreationSuccess"
                text={t("common.continueButton")}
                intent="navigational"
                color="info"
                fullWidth
                onClick={onContinueButtonClick}
              >
                {t("common.continueButton")}
              </ButtonWithAnalytics>
            </Stack>

            <FullscreenBackground color="primary.main" />
          </Stack>
        </Stack>
      )}
    </>
  );
}
